<template>
  <div id="grid" :class="{ imageGrid: true, 'fade-in': imageLoaded }">
    <div v-if="details" :class="{ imageWrapper: true }">
      <span v-if="isElectric" class="electricTag">
        <LeafIcon />
      </span>
      <img :on-load="onImageLoad()" :src="url" class="mainImage" data-test-id="listing_details_main_image" @click="onClickThumb(0)" />
      <div class="favoriteSession">
        <button v-if="details" class="favorite" data-test-id="listing_details_favorite" @click.stop="toggleFavorite">
          <svg width="28" height="28" viewBox="0 0 24 21" fill="#000000" xmlns="http://www.w3.org/2000/svg">
            <path v-if="!favorited" d="M20.8516 2.45703C19.6484 1.45443 18.2878 1.01042 16.7695 1.125C15.2799 1.23958 13.9766 1.86979 12.8594 3.01562L12 3.91797L11.1406 3.01562C10.224 2.04167 9.02083 1.42578 7.53125 1.16797C6.01302 0.910156 4.55208 1.33984 3.14844 2.45703C1.80208 3.66016 1.08594 5.12109 1 6.83984C0.942708 8.52995 1.51562 10.0482 2.71875 11.3945L11.0117 19.9453C11.2982 20.2318 11.6276 20.375 12 20.375C12.3724 20.375 12.6875 20.2318 12.9453 19.9453L21.2812 11.3516C22.4844 10.0339 23.0573 8.52995 23 6.83984C22.9141 5.12109 22.1979 3.66016 20.8516 2.45703ZM20.293 10.4062L12 19L3.70703 10.4062C2.84766 9.51823 2.40365 8.40104 2.375 7.05469C2.34635 5.73698 2.90495 4.54818 4.05078 3.48828C5.25391 2.57161 6.44271 2.27083 7.61719 2.58594C8.76302 2.90104 9.60807 3.3737 10.1523 4.00391L12 5.89453L13.8477 4.00391C14.3919 3.40234 15.237 2.92969 16.3828 2.58594C17.5573 2.27083 18.7604 2.57161 19.9922 3.48828C21.1094 4.54818 21.6536 5.73698 21.625 7.05469C21.5964 8.3724 21.1523 9.48958 20.293 10.4062Z" fill="#000000" />
            <path v-if="favorited" d="M20.8516 2.45703C19.6484 1.45443 18.2878 1.01042 16.7695 1.125C15.2799 1.23958 13.9766 1.86979 12.8594 3.01562L12 3.91797L11.1406 3.01562C10.224 2.04167 9.02083 1.42578 7.53125 1.16797C6.01302 0.910156 4.55208 1.33984 3.14844 2.45703C1.80208 3.66016 1.08594 5.12109 1 6.83984C0.942708 8.52995 1.51562 10.0482 2.71875 11.3945L11.0117 19.9453C11.2982 20.2318 11.6276 20.375 12 20.375C12.3724 20.375 12.6875 20.2318 12.9453 19.9453L21.2812 11.3516C22.4844 10.0339 23.0573 8.52995 23 6.83984C22.9141 5.12109 22.1979 3.66016 20.8516 2.45703" />
          </svg>
        </button>
      </div>
      <span v-if="tagText()" :class="`reservedTag ${tagClass}`">{{ tag }}</span>
    </div>
    <ListingThumbs :media-array="mediaArray" @click="onClickThumb" />
    <ModalListing @main-image-load="imageLoaded" v-if="showModal" @close="showModal = false" :active-index="activeIndex" :media-array="mediaArray" :listing-id="details.id" />
  </div>
</template>

<script>
import ListingThumbs from './ListingThumbs.vue'
import ModalListing from './ModalListing.vue'
import LeafIcon from '../../resources/icons/leaf.svg'
import CarPlaceholder from '../../resources/car.webp'
import { getImageUrl } from '../../lib/helper'
import { LISTING } from '../../lib/constants'

const listingStates = {
  SOLD: 'sold',
  RESERVED: 'reserved'
}

const videoTypes = {
  VZAAR: 'vzaar',
  VIMEO: 'vimeo'
}

export default {
  name: 'ImageGrid',
  components: { ListingThumbs, ModalListing, LeafIcon },
  props: {
    details: { type: Object, default: null }
  },
  emits: ['loadImage'],
  data() {
    return {
      favorites: window.seezSdk.getFavorites(),
      tag: '',
      tagClass: '',
      activeIndex: null,
      showModal: false,
      mediaArray: [],
      imageLoaded: false
    }
  },
  computed: {
    t() {
      return LISTING
    },
    isElectric() {
      return ['El', 'Electricity'].includes(this.details.fuelType.name)
    },
    url() {
      if (this.details.images.length === 0) return CarPlaceholder
      return getImageUrl(this.details.id, this.details.images[0], 'xl')
    },
    favorited() {
      return this.favorites.includes(parseInt(this.details.id))
    }
  },
  watch: {
    showModal() {
      if (this.showModal) this.$root.track(this.$root.events.LISTING_MODAL_OPEN, { listingId: this.details.id })
    }
  },
  mounted() {
    this.loadMediaOptions()
  },
  methods: {
    onImageLoad() {
      this.$emit('loadImage')
      this.imageLoaded = true
    },
    loadMediaOptions() {
      const mediaImageArr = this.details.images.map((_, index) => ({
        type: 'image',
        value: getImageUrl(this.details.id, this.details.images[index], 'xl'),
        thumbUrl: getImageUrl(this.details.id, this.details.images[index], 'xs')
      }))
      if (this.details.videos && this.details.videos.length > 0) {
        const videoOptions = this.details.videos.map(item => ({ type: 'video', value: item, source: this.handleVideoType(item), thumbUrl: this.handleThumbUrl(item) }))
        this.$root.media = videoOptions[0]
        return (this.mediaArray = [...videoOptions, ...mediaImageArr])
      }
      return (this.mediaArray = mediaImageArr)
    },
    onClickThumb(v) {
      this.activeIndex = v
      this.showModal = true
    },
    handleVideoType(url) {
      if (url.includes('vzaar')) return videoTypes.VZAAR

      return videoTypes.VIMEO
    },
    handleThumbUrl(url) {
      if (url.includes('vzaar')) return url.replace('/player', '/image')
      // this url retrieves a vmeo thumb by getting an id
      // TODO: RETRIEVE ID FROM VIMEO URL AND RETURN THE STRING BELOW
      return 'https://vumbnail.com/766474402.jpg'
    },
    tagText() {
      if (!this.details) return
      if (['reserved', 'reservedOnSeez'].includes(this.details.state)) {
        this.tag = this.t.reserved
        this.tagClass = listingStates.RESERVED
        return true
      }
      if (['unavailable', 'sold'].includes(this.details.state)) {
        this.tag = 'sold'
        this.tagClass = listingStates.SOLD
        return true
      }
      return false
    },
    toggleFavorite() {
      const favourited = window.seezSdk.setFavorite(this.details.id)
      this.favorites = window.seezSdk.getFavorites()
      this.$root.track(this.$root.events.FAVOURITE_CAR, { listing_id: parseInt(this.details.id), favourited, event_label: { listing_id: this.details.id }, properties: { type: 'listing_page' } })
    },
    setSelectedImage(value) {
      this.activeIndex = value.index
      const url = getImageUrl(this.details.id, value.index)
      this.selectedImage = url
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.fade-in {
  animation: fadeIn 2s;
}

.imageGrid {
  grid-area: imageWrapper;

  @include respond(st-48) {
    margin-top: 2rem;
    padding: 0;
  }

  .imageWrapper {
    position: relative;

    .mainImage {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 18.75rem;
      aspect-ratio: 4 / 3;
      cursor: pointer;
      margin: 0 0 -2px 0;
      border-radius: 16px 16px 0 0;

      @media screen and (min-width: 48rem) {
        height: 31rem;
        aspect-ratio: 16 / 9;
        border-radius: 16px 16px 0 0;
      }
    }

    .favoriteSession {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      position: absolute;
      bottom: 0.625rem;
      right: 0.625rem;

      .favorite {
        background-color: white;
        border: none;
        height: 3.4em;
        width: 3.4em;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    > .reserved {
      background-color: var(--orange);
    }

    > .sold {
      background-color: var(--red);
    }

    > .electricTag {
      background: linear-gradient(125.11deg, #5bc2f4 39.19%, rgba(91, 194, 244, 0.33) 154.06%);
      position: absolute;
      width: 2.188rem;
      height: 2.188rem;
      right: 0;
      top: 0;
      border-radius: 0 12px 0 12px;

      @include respond(st-48) {
        width: 2.2rem;
        border-radius: 0px 12px;
      }

      > svg {
        margin: 9px 11px;
      }
    }

    > .reservedTag {
      font-size: 0.8em;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.25em 1em;
      border-radius: 0px 12px 12px 0px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      color: white;
      font-weight: 700;
      margin: 1rem 0;
      min-width: 5.375em;

      @include respond(st-48) {
        margin: 2.5em 0;
        min-width: 8.375em;
        font-size: 1em;
      }
    }
  }

  @media screen and (max-width: 60rem) and (orientation: landscape) {
    .modalListing {
      padding-top: 0;
      padding-bottom: 0;
      .title {
        width: calc(100% + 2em);
        position: fixed;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        left: 0;
        margin-left: -1em;
        margin-right: -1em;
        padding: 1em 0;
        > div {
          padding: 0 1em;
        }
        > button {
          margin: 0 1em;
        }
      }
      .carousel {
        max-width: 100%;
        width: 100%;
        > .listings > .item {
          min-width: 100%;
          > img {
            max-height: 100%;
          }
        }
      }
    }
    .thumbsContainer {
      width: 100%;
      position: fixed;
      bottom: 1.5rem;
      text-align: center;
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      margin-bottom: 0;
      img {
        content: '';
        width: 0;
        height: 0;
        background: #fff;
        border-radius: 50%;
      }
    }
  }
}
</style>
