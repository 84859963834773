<template>
  <div :class="{ listingInfo: true, 'fade-in': true }">
    <div class="cta">
      <div class="brochureVATrow">
        <ul>
          <li>
            <a class="iconItem" @click="onClickBrochure">
              <DownloadIcon />
              Print
            </a>
          </li>
          <span>
            <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="#333333" />
            </svg>
          </span>
          <li class="iconItemNo">
            <MapPinIcon />
            {{ location }}
          </li>
          <li>
            <span v-if="vat" class="tagVAT">{{ VATdetails }}</span>
          </li>
        </ul>
      </div>
      <div class="model-make">
        <h1 data-test-id="listing_details_name">{{ details.name }}</h1>
        <p v-if="details.variant">
          {{ details.variant }}
          {{ details.year }} &middot;
          {{ formatSpec(details.kilometrage, 'km') }}
        </p>
      </div>
      <section v-if="showLeasingInfo && details && details?.leasePrice" class="leasingInfo">
        <div class="leasingHeader">
          <div>
            <h2>{{ leasingTypeHeading }}</h2>
            <p>{{ t.leasing_from }}</p>
          </div>
          <p class="price">{{ leasePriceString }}</p>
        </div>
        <ul class="leasingDetails">
          <li>
            <span class="label">{{ t.downpayment }}</span>
            <span class="value">{{ $root.formatNumber(details.leasePrice.lease?.downPayment, 0, details.leasePrice.currency) }}</span>
          </li>
          <li v-if="privateLease">
            <span class="label">{{ t.kmIncluded }}</span>
            <span class="value">{{ yearlyKilometrage }}</span>
          </li>
          <li v-else>
            <span class="label">{{ t.residual_value }}</span>
            <span class="value">{{ $root.formatNumber(details.leasePrice.lease?.residualValue, 0, details.leasePrice.currency) }}</span>
          </li>
          <li>
            <span class="label">{{ t.duration }}</span>
            <span class="value">{{ `${details.leasePrice.lease?.duration} ${t.months}` }}</span>
          </li>
          <li>
            <p class="value note">{{ leasingVatLabel }}</p>
          </li>
        </ul>
      </section>
      <section v-else class="financingInfo">
        <div class="prices">
          <div v-if="retailPriceString">
            <span>Kontant</span>
            <div>
              <p class="retailPrice" data-test-id="listing_details_price">{{ retailPriceString }}</p>
              <p v-if="!wholesale">Inkl. levering</p>
            </div>
          </div>
          <div v-if="!wholesale && loanPriceString !== '-'">
            <span>Finansering fra</span>
            <div>
              <p :class="{ financingPrice: true, noPrice: loanPriceString === '-' }" data-test-id="listing_details_price" @click="scrollTo">{{ loanPriceString }}</p>
              <p v-if="loanCalculation">Løbetid: {{ loanCalculation.paymentTerms }} mdr.</p>
            </div>
          </div>
          <div v-if="!wholesale && hasLeasePrice">
            <span>Leasing fra</span>
            <div v-click-outside="clickOutside" class="leaseWrapper">
              <p :class="{ leasingPrice: true, noPrice: !hasLeasePrice, defaultCursor: !hasSliderOffers }" data-test-id="listing_details_price">
                <span @click="hasSliderOffers ? scrollTo('sliderOffers') : null">{{ leasePriceString }}</span>
                <button v-if="hasLeasePrice" class="infoButton" @click="showTooltip = !showTooltip">
                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 0.75C3.11719 0.75 0 3.89453 0 7.75C0 11.6328 3.11719 14.75 7 14.75C10.8555 14.75 14 11.6328 14 7.75C14 3.89453 10.8555 0.75 7 0.75ZM7 13.4375C3.85547 13.4375 1.3125 10.8945 1.3125 7.75C1.3125 4.63281 3.85547 2.0625 7 2.0625C10.1172 2.0625 12.6875 4.63281 12.6875 7.75C12.6875 10.8945 10.1172 13.4375 7 13.4375ZM8.09375 9.9375H7.65625V7.53125C7.65625 7.17578 7.35547 6.875 7 6.875H6.125C5.74219 6.875 5.46875 7.17578 5.46875 7.53125C5.46875 7.91406 5.74219 8.1875 6.125 8.1875H6.34375V9.9375H5.90625C5.52344 9.9375 5.25 10.2383 5.25 10.5938C5.25 10.9766 5.52344 11.25 5.90625 11.25H8.09375C8.44922 11.25 8.75 10.9766 8.75 10.5938C8.75 10.2383 8.44922 9.9375 8.09375 9.9375ZM7 6C7.46484 6 7.875 5.61719 7.875 5.125C7.875 4.66016 7.46484 4.25 7 4.25C6.50781 4.25 6.125 4.66016 6.125 5.125C6.125 5.61719 6.50781 6 7 6Z" fill="#5BC2F4" />
                  </svg>
                </button>
              </p>
              <!-- Tootip start  -->
              <Transition name="fade" @click.stop.prevent="showTooltip = !showTooltip">
                <div v-if="showTooltip && hasLeasePrice" class="toolTip">
                  <span class="closeIcon">
                    <CloseIcon />
                  </span>
                  <h3>Leasingoplysninger</h3>
                  <ul class="descriptionList">
                    <li v-for="item in priceItems" :key="item.id">
                      {{ item.key }}<span>{{ item.value }}</span>
                    </li>
                    <li class="priceInfo">{{ leasingVatLabel }}</li>
                  </ul>
                  <div class="calculatorLink">
                    <p>Månedlig rate (12 betalinger)</p>
                    <a @click.prevent="onClickLead()" href="#">
                      <ChatIcon />
                      <span>Kontakt os</span>
                      <ArrowRightIcon />
                    </a>
                  </div>
                </div>
              </Transition>
              <!-- Tootip end -->
            </div>
          </div>
        </div>
        <button data-test-id="listing_details_buy_button" :disabled="buyDisabled" @click="onClick">
          {{ ctaLabel }}
        </button>
      </section>
      <div class="contactAndTestDriveRow" :class="{ lease: showLeasingInfo }">
        <button v-if="showContactButton" @click="onClickLead" aria-label="contact us button" data-test-id="contact_button" class="contactUsBtn">
          <ChatIcon />
          Kontakt os
        </button>
        <span v-if="showContactButton"
          ><svg width="1" height="22" viewBox="0 0 1 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="-2.18557e-08" x2="0.500001" y2="22" stroke="#CBCBCB" />
          </svg>
        </span>
        <button :disabled="buyDisabled" @click="onClickTestDrive" aria-label="test drive button" data-test-id="test_drive_button" class="testDriveBtn">
          <WheelIcon />
          Book prøvetur
        </button>
      </div>
      <div v-if="!wholesale && showLeasingCalculator && details.referenceNumber.match(/\d+/)" id="findleasing-offer-embed-div" class="sliderOffersWrapper" :class="{ hidden: !hasSliderOffers }" ref="sliderOffers">
        <h2 id="leasingHeader" class="sliderOffersHeading">Leasingberegner</h2>
        <div data-findleasing :data-seller="dataSeller" :data-id="details.referenceNumber" data-funding="financial" />
      </div>
      <div v-if="showLeasingInfo && details?.retailPrice?.value" class="pricesBox">
        <div>
          <h3>{{ t.financing_from }}</h3>
          <b>{{ loanPriceString }}</b>
        </div>
        <div>
          <h3>{{ t.cash }}</h3>
          <b>{{ retailPriceString }}</b>
        </div>
      </div>
      <div class="tradeInputWrapper" v-if="!wholesale">
        <div class="loadingPriceInfo">
          <TradeInputContainer @click-trade-in="clickTradeInput" />
        </div>
        <span v-if="loanCalculation" :class="[{ paymentSmall: true }]" v-html="loanCalculation.disclaimer" />
      </div>
      <div v-else class="wholesaleInfoWrapper">
        <div class="wholesaleInfo">
          <h3>Lad en uvildig Recar mekaniker kontrollere bilen for eventuelle fejl og mangler.</h3>
          <ul>
            <li>140 punkts tjek</li>
            <li>Uafhængig gennemgang af ekstern mekaniker</li>
            <li>Uddybende rapport af eventuelle fejl</li>
          </ul>
          <div>
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 0.5C2.67188 0.5 0 3.19531 0 6.5C0 9.82812 2.67188 12.5 6 12.5C9.30469 12.5 12 9.82812 12 6.5C12 3.19531 9.30469 0.5 6 0.5ZM6 11.375C3.30469 11.375 1.125 9.19531 1.125 6.5C1.125 3.82812 3.30469 1.625 6 1.625C8.67188 1.625 10.875 3.82812 10.875 6.5C10.875 9.19531 8.67188 11.375 6 11.375ZM6.9375 8.375H6.5625V6.3125C6.5625 6.00781 6.30469 5.75 6 5.75H5.25C4.92188 5.75 4.6875 6.00781 4.6875 6.3125C4.6875 6.64062 4.92188 6.875 5.25 6.875H5.4375V8.375H5.0625C4.73438 8.375 4.5 8.63281 4.5 8.9375C4.5 9.26562 4.73438 9.5 5.0625 9.5H6.9375C7.24219 9.5 7.5 9.26562 7.5 8.9375C7.5 8.63281 7.24219 8.375 6.9375 8.375ZM6 5C6.39844 5 6.75 4.67188 6.75 4.25C6.75 3.85156 6.39844 3.5 6 3.5C5.57812 3.5 5.25 3.85156 5.25 4.25C5.25 4.67188 5.57812 5 6 5Z" fill="#5BC2F4" />
            </svg>
            <button id="learn-more-wholesale" aria-label="wholesale button info" @click="scrollTo">Lær mere</button>
          </div>
        </div>
        <p>AFHENTNINGSBIL, SÆLGES KUN TIL CVR-NUMMER UDEN GARANTI</p>
      </div>
    </div>
  </div>
</template>

<script>
import TradeInputContainer from './TradeInputContainer.vue'
import DownloadIcon from '../../resources/icons/download_icon.svg'
import MapPinIcon from '../../resources/icons/map_pin_icon.svg'
import WheelIcon from '../../resources/icons/steering_wheel_icon.svg'
import ChatIcon from '../../resources/icons/chat_icon.svg'
import ArrowRightIcon from '../../resources/icons/arrow-right.svg'
import CloseIcon from '../../resources/icons/close-icon.svg'
import { querier } from '../../lib/querierMixin'
import { formatSpec } from '../../lib/helper'
import { LISTING } from '../../lib/constants'

const vatOptions = {
  VAT_INCLUDE: 'vanMinusTax',
  VAT_PLUS: 'vanPlusTax',
  VAT_NONE: 'van'
}

const leasingTypes = {
  OPERATIONAL: 'operational',
  FINANCIAL: 'financial'
}

export default {
  name: 'ListingInfo',
  components: { DownloadIcon, TradeInputContainer, MapPinIcon, ChatIcon, WheelIcon, ArrowRightIcon, CloseIcon },
  mixins: [querier],
  props: {
    details: { type: Object, required: true },
    loanCalculation: { type: Object, default: null },
    wholesale: { type: Number, default: null },
    selectedPriceType: { type: String, default: '' }
  },
  emits: ['clickTradeIn', 'showLeadModal', 'showTestDriveModal', 'clickBuy', 'scrollTo'],
  data() {
    return {
      dataSeller: 80,
      buyDisabled: null,
      user: null,
      showTooltip: false,
      hasSliderOffers: true
    }
  },
  computed: {
    yearlyKilometrage() {
      if (this.details.leasePrice.lease?.yearlyKmtrage < 0) return '-'
      return this.$root.formatNumber(this.details.leasePrice.lease?.yearlyKmtrage, 0)
    },
    showLeasingInfo() {
      return this.selectedPriceType === 'lease'
    },
    showLeasingCalculator() {
      const { retailPrice, leasePrice, wholesaleLeasePrice } = this.details
      if ((leasePrice?.value || wholesaleLeasePrice?.value) && !retailPrice?.value) return true
      if (retailPrice?.value && !leasePrice?.value && !wholesaleLeasePrice?.value) return false
      return true
    },
    selectedPrice() {
      if (this.details == null) return null
      return this.wholesale ? this.details.wholesalePrice ?? this.details.retailPrice : this.details.retailPrice ?? this.details.wholesalePrice
    },
    priceItems() {
      const { wholesaleLeasePrice } = this.details
      const priceType = wholesaleLeasePrice?.value ? 'wholesaleLeasePrice' : 'leasePrice'
      const { lease } = this.details[priceType]
      return [
        { key: 'Løbetid', value: `${lease.duration} mdr.`, id: `${priceType}-1` },
        { key: 'Udbetaling:', value: this.$root.formatNumber(lease.downPayment, 0, lease.currency), id: `${priceType}-2` },
        { key: 'Månedlig ydelse:', value: this.$root.formatNumber(this.details[priceType].value, 0, this.details[priceType].currency), id: `${priceType}-3` },
        { key: 'Restværdi:', value: this.$root.formatNumber(lease.residualValue, 0, lease.currency), id: `${priceType}-4` },
        { key: 'Leasingtype:', value: `${this.t[lease.type]} ${this.t[lease.audience]}`, id: `${priceType}-5` }
      ]
    },
    t() {
      return LISTING
    },
    hasRetailPrice() {
      return this.details?.retailPrice?.value > 0
    },
    showContactButton() {
      if (this.details.state === 'unavailable') return false
      if (this.wholesale) return false
      if (!this.hasRetailPrice) return false
      return true
    },
    location() {
      return this.details.locatedAt.plainAddress.split(',')[1]
    },
    privateLease() {
      return this.details?.leasePrice?.lease?.audience === 'private'
    },
    vat() {
      return Object.values(vatOptions).includes(this.selectedPrice?.disclaimer)
    },
    leasingVatLabel() {
      if (this.privateLease) return this.t.vat_leasing_include
      return this.t.vat_excluded
    },
    leasingTypeHeading() {
      if (this.privateLease) return this.t.private
      return this.t.business
    },
    formatSpec() {
      return formatSpec
    },
    retailPriceString() {
      const d = this.details
      const priceValue = d.retailPrice?.value ?? d.wholesalePrice?.value
      return this.$root.formatNumber(priceValue, 0, d.currency)
    },
    loanPriceString() {
      if (!this.hasRetailPrice) return '-'
      if (this.loanCalculation == null || !this.loanCalculation.monthlyPayment) return null
      return this.$root.formatNumber(this.loanCalculation.monthlyPayment, 0, this.details.currency) + ' * '
    },
    leasePriceString() {
      const d = this.details
      const priceValue = d.leasePrice?.value ?? d.wholesaleLeasePrice?.value
      return this.$root.formatNumber(priceValue, 0, d.currency) ?? '-'
    },
    hasLeasePrice() {
      const { leasePrice, wholesaleLeasePrice } = this.details
      return Boolean(leasePrice?.value || wholesaleLeasePrice?.value)
    },
    ctaLabel() {
      if (this.wholesale || !this.hasRetailPrice) return 'Kontakt os'
      if (this.buyDisabled) return 'Kommer snart'
      if (this.user && this.details?.reservedBy === this.user.id) return this.t.resume_checkout
      return 'Start dit køb'
    },
    VATdetails() {
      if (this.details.retailPrice?.disclaimer === vatOptions.VAT_INCLUDE || this.details.retailPrice?.disclaimer === vatOptions.VAT_NONE) return this.t.vat_include
      if (this.details.retailPrice?.disclaimer === vatOptions.VAT_PLUS) return this.t.vat_plus
      return ''
    }
  },
  mounted() {
    if (this.details.state === 'unavailable') {
      this.buyDisabled = true
    }
    this.embedFindLeasingSliderOffersScript()
  },
  methods: {
    embedFindLeasingSliderOffersScript() {
      const offerEmbedScript = document.createElement('script')
      offerEmbedScript.setAttribute('src', 'https://www.findleasing.nu/offer-sliders.js')
      document.head.appendChild(offerEmbedScript)
      // Hide the element and only set the hasSliderOffers to true, if the message event gets the init cmd
      this.hasSliderOffers = false
      window.addEventListener('message', this.receiveFindLeasingMessage, false)
    },
    isFindLeasingData(s, identifier) {
      return s.substr(0, identifier.length) === identifier
    },
    receiveFindLeasingMessage(evt) {
      // Set the hasSliderOffers to true, if the message event gets the init cmd
      const identifier = '[FindLeasing]'
      if (evt.origin !== 'https://www.findleasing.nu' || !this.isFindLeasingData(evt.data, identifier)) return
      let data = evt.data.substr(identifier.length, evt.data.length).split(':')
      let cmd = data[0]
      if (cmd === 'init') this.hasSliderOffers = true
    },
    clickOutside() {
      this.showTooltip = false
    },
    onClick() {
      if (this.wholesale || !this.hasRetailPrice) return this.onClickLead()
      this.$emit('clickBuy')
    },
    onClickBrochure() {
      this.$root.track(this.$root.events.CTA_CLICK, { properties: { event_label: 'download_brochure' } })
      window.print()
    },
    scrollTo(reference) {
      if (reference) this.$emit('scrollTo', reference)
      else this.$emit('scrollTo')
    },
    onClickLead() {
      this.$emit('showLeadModal')
    },
    onClickTestDrive() {
      this.$emit('showTestDriveModal')
    },
    clickTradeInput(v) {
      this.$emit('clickTradeIn', v)
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

.listingInfo {
  margin: 2rem 0;

  @include respond(st-48) {
    margin: 2rem 1rem;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  > .cta {
    grid-area: cta;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    .model-make {
      > h1 {
        margin-block-start: 0;
        margin-block-end: 0.25rem;
        color: #333333;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }

      > p {
        margin-block-start: 0;
      }

      > .yearkm {
        margin-block-start: 1rem;
        margin-block-end: 0;
      }
    }

    .brochureVATrow {
      ul {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        padding: 0 0 1rem 0;

        li {
          font-family: var(--font-family);
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 0.875rem;
        }
      }

      span {
        svg {
          vertical-align: middle;
        }
      }

      .iconItemNo {
        text-decoration: none;
        display: block;
        font-family: var(--font-family);
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
        color: var(--black) !important;
      }

      .iconItem {
        text-decoration: none;
        display: block;
        font-family: var(--font-family);
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
        color: var(--black) !important;

        cursor: pointer;

        > * {
          vertical-align: text-bottom;
        }

        > svg {
          margin-right: 0.2em;
          height: 1.2em;
        }
      }

      .tagVAT {
        background: rgba(91, 194, 244, 0.15);
        border-radius: 20px;
        padding: 0.5rem;
        font-size: 0.8rem;
      }
    }

    .brochureLink {
      color: rgba(91, 194, 244, 1);
      text-decoration: none;
      display: block;
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
      cursor: pointer;

      > * {
        vertical-align: text-bottom;
      }

      > svg {
        margin-right: 0.2rem;
        height: 1.2rem;
      }
    }

    > .leasingInfo {
      border-top: 1px solid #eeeeee;
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      margin-top: 1rem;
      padding: 1rem;

      .leasingHeader {
        display: flex;
        justify-content: space-between;

        div > h2 {
          margin: 0;
          font-size: 1rem;
          font-weight: bold;
          color: #000;
        }

        div > p {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin-top: 2px;
        }
      }

      .price {
        font-size: 2rem;
        font-weight: bold;
        color: #000;
      }

      .leasingDetails {
        margin-top: 10px;
        border-radius: 9px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(245, 245, 245, 0.8);

        li {
          display: flex;
          justify-content: space-between;
          padding: 6.5px 0;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
          margin: 0 10px;

          .label {
            color: rgba(0, 0, 0, 0.8);
            font-size: 12.8px;
            font-weight: 400;
          }

          .value {
            color: #000;
            font-size: 12.8px;
            font-weight: 600;
          }

          .customizeLink {
            color: var(--highlight);
            font-size: 10px;
            font-weight: 600;
          }

          .note {
            font-weight: 400;
            font-size: 10px;
            margin-inline-start: auto;
          }
        }
      }
    }

    > .financingInfo {
      border-top: 1px solid #eeeeee;
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      margin-top: 1rem;
      padding: 1rem 1.5rem;

      .leaseWrapper {
        position: relative;

        .infoButton {
          background: transparent;
          padding: 0;
          margin: 0;
          cursor: pointer;
        }

        .toolTip {
          position: absolute;
          background-color: white;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
          border-radius: 12px;
          font-size: 10px;
          max-width: 21rem;
          min-width: 20rem;
          min-height: 7.5rem;
          padding: 1rem 0 0 0;
          right: -1rem;
          top: 2rem;
          z-index: 5;

          .closeIcon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
          }

          h3 {
            font-weight: 400;
            font-size: 20px;
            line-height: 22px;
            padding: 0 1rem;
            color: black;
            font-family: var(--font-heading);
            margin-bottom: 0.5rem;
          }

          .border {
            > :last-child {
              border-top: 1px solid #8e8e8e;
              padding: 0.5rem 0;
            }
          }

          > .descriptionList {
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 0 1rem;
            color: black;
            li {
              font-size: 14px;
              padding: 4px 0;

              .noPayment {
                text-decoration: none;
                color: black;

                > span {
                  text-decoration: underline;
                }
              }

              > span {
                font-weight: 700;
                padding-inline-start: 0.3rem;
              }
            }

            .priceInfo {
              padding-top: 0.813rem;
              font-size: 0.625rem;
            }
          }
          > p {
            font-size: 8px;
            padding: 0 1rem;
            margin: 0;
            color: black;
          }

          .calculatorLink {
            background-color: #f5f5f5;
            padding: 13px 31px;
            text-align: center;
            margin-top: 1rem;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;

            > p {
              font-family: var(--font-family);
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              padding-bottom: 0.219rem;
            }

            > a {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.3rem;
              font-size: 14px;
              text-decoration: none;
              color: var(--highlight);
              font-weight: 600;

              > svg > path {
                fill: var(--highlight);
              }
            }
          }
        }
      }

      .prices {
        display: flex;
        flex-direction: column;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem;

          > span {
            font-size: 1rem;
            font-family: var(--font-family);
            font-weight: 400;
          }

          .retailPrice {
            font-weight: 700;
            @media screen and (min-width: 60rem) {
              font-size: 1.563rem;
            }
          }

          &:last-of-type:not(:first-of-type) {
            border-top: 1px solid var(--gray-20);
          }
          &:last-of-type:first-of-type p.leasingPrice {
            font-size: 1.563rem;
            line-height: 1;
          }
        }

        hr {
          height: 3.313rem;
          width: 1px;
          background-color: #e2e2e2;
          border: none;
        }

        p {
          margin: 0;
          font-size: 1rem;
          font-weight: bold;
          color: var(--dark-gray-90);
          font-weight: 900;
        }

        p:nth-of-type(2) {
          font-size: 0.75rem;
          font-weight: 400;
          text-align: end;
        }

        .leaseWrapper .leasingPrice {
          display: flex;
          flex-direction: row;
          align-items: end;
          .infoButton {
            margin-left: 0.5rem;
          }
        }

        .leaseWrapper .financingPrice,
        .leasingPrice:not(.defaultCursor) {
          color: var(--highlight);
          text-align: end;
        }

        .leasingPrice:not(.defaultCursor) > span,
        .financingPrice {
          color: var(--highlight);
          text-decoration: underline;
          cursor: pointer;
        }

        .financingPrice:hover {
          opacity: 75%;
        }

        .noPrice {
          color: var(--black);
          text-decoration: none;
        }
      }

      > button {
        cursor: pointer;
        background-color: var(--highlight);
        border: none;
        display: block;
        border-radius: 12px;
        width: 100%;
        padding: 0.75em 1.5em;
        font-size: 1.15em;
        font-weight: 500;
        color: var(--white);
        font-family: var(--font-family);
        margin-top: 1rem;
        text-transform: uppercase;

        &:hover {
          background-color: rgba(91, 194, 244, 0.8);
        }

        &:disabled {
          background-color: var(--dark-gray);
          cursor: not-allowed;
        }
      }
    }

    > .contactAndTestDriveRow {
      display: flex;
      border-bottom: 1px solid #eeeeee;
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      background-color: var(--light-gray);
      align-items: center;

      &.lease {
        flex-direction: column;
        background-color: white;

        button.contactUsBtn {
          background-color: var(--highlight);
          color: white;
          text-decoration: none;
          border-radius: 12px;
          padding: 12px;
          max-width: 90%;
          margin: auto;
          font-weight: 700;
          font-size: 1rem;

          svg {
            display: none;
          }
        }

        button.testDriveBtn {
          background: rgba(245, 245, 245, 0.8);
          margin-top: 10px;
          border-radius: 0px 0px 1rem 1rem;
        }

        > span {
          display: none;
        }
      }

      > button > svg > path {
        fill: var(--black);
      }

      > span > svg {
        transform: translateY(0.2rem);
      }

      button {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: underline;
        font-family: var(--font-family);
        background-color: transparent;
        color: var(--black);
        cursor: pointer;
        font-size: 1rem;
        white-space: nowrap;

        &:disabled {
          cursor: not-allowed;
          color: var(--dark-gray);
        }

        > svg {
          padding-inline-end: 0.5rem;
        }
      }
    }

    .pricesBox {
      display: flex;
      justify-content: space-between;
      gap: 1.125rem;
      margin-bottom: 0.188rem;
      margin-top: 11px;

      div {
        border-radius: 0.938rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(247, 247, 247, 0.6);
        width: 100%;
        padding: 0.5rem 0.688rem;
        border-radius: 0.938rem;
        text-align: center;

        h3 {
          font-size: 1rem;
          font-weight: 400;
        }
        b {
          font-size: 1.25rem;
        }
      }
    }
  }

  .tradeInputWrapper > .paymentSmall {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 0.765rem;
    line-height: 1rem !important;
    color: var(--dark-gray);
  }

  .wholesaleInfoWrapper {
    margin: 0.75rem 0;

    > .wholesaleInfo {
      border: 1px solid #eeeeee;
      border-radius: 1rem;
      padding: 0.688rem 1rem;

      > h3 {
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: var(--black);
        max-width: 90%;
      }

      ul {
        list-style-position: inside;
        margin-top: 0.688rem;
        list-style-type: none;
        list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACgSURBVHgBrY9NCsIwEIVfolkILuoNcgRPIOQW4qpHc+M5Bm/gDcwN2oWgEGw6pTRQWpr0561CXr4vM8DKCMzMlQotpSIhYB+Xo9kvhHX3t1wIW/WXprlPWmEMvpuDDYLb8/PmQ6kqx8WpTIXDCoJbVp0dP8ypyFLhMEFOX+12FQFew+PVTPIDshgcBGMSz4IY3BMMJG09CQ8EfQkQgzdJDUuJXWoF+3MwAAAAAElFTkSuQmCC');

        > li {
          margin: 0.313rem 0;
          padding: 0.2rem 0;
        }
      }

      div {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        > button {
          background-color: transparent;
          font-family: var(--font-family);
          color: var(--highlight);
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            opacity: 0.75;
          }
        }
      }
    }

    > p {
      margin-top: 1rem;
      font-family: var(--font-family);
      font-size: 0.8rem;
      line-height: 1rem;
      letter-spacing: -0.02em;
    }
  }

  .sliderOffersWrapper {
    margin: 18px 0 0 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 0.5rem 0;

    > .sliderOffersHeading {
      font-family: var(--font-heading);
      font-weight: 400;
      font-size: 1.5rem;
      margin: 0;
      color: black;
      padding: 0 1rem;
      transform: translateY(0.5rem);
    }

    &.hidden {
      display: none;
    }
  }
  .hidden * {
    display: none;
  }
}
</style>
